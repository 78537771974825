<template>
  <div class="feed-body">
    <div class="feed-contents">
      <reedeemed-card
      
        style="width: 100%"
        class="mb-3"
      />
      <div v-if="awards.length == 0 && !loading" class="my-6 white--text">
        No awards
      </div>
      <v-progress-circular indeterminate color="amber" v-if="loading" />
    </div>
    <v-snackbar v-model="snackbar">
      {{ errorMessage }}
    </v-snackbar>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import ReedeemedCard from "./ReedemedCards.vue"; 

export default {
  components: {   
    ReedeemedCard
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      loadCompleted: -1,
      loadingMore: false,
      picker: null,
      createDialog: false,
      eligibleList: [],
      eligibles: []
    };
  },
  methods: {
    ...mapActions("awards", {
      getAllAwards: "fetchAllAwards",
      getEligibles: "getEligibles"
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    loadData() {
      this.loading = true;
      this.getAllAwards()
        .then(() => {
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          if (error.response == undefined) {
            this.showErrorMessage(error);
          } else {
            this.showErrorMessage(error.response.data.message);
          }
        });

      if (this.profileType == "Company") {
        this.getEligibles()
          .then(res => {
            this.eligibles = res;
            this.eligibleList = res;
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    onCreateAward() {
      this.createDialog = true;
    },
    onCloseDialog() {
      this.createDialog = false;
    },
    formatMinutes(value) {
      var hrs = Math.floor(value / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(value % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
    goToProfile(user) {
      this.$router.push({
        name: "volunteer-profile-details",
        params: { id: user._id }
      });
    }
  },
  computed: {
    ...mapGetters({
      awards: "awards/getAllAwards",
      profile: "profile",
      profileType: "auth/getType"
    })
  },
  mounted() {
    this.loadData();
  }
};
</script>
<style scoped>
.feed-body {
  width: 100%;
  min-width: 200px;
  margin-left: 0px;
  margin-right: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
}
.feed-contents {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.date-picker :deep(.v-picker__title) {
  background: #cde539 !important;
  color: #242f36;
  font-weight: 700;
  font-family: Poppins-Bold;
}
</style>
