var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.reedemedCoupons),function(coupon){return _c('v-card',{key:coupon._id,staticStyle:{"width":"100%"}},[_c('v-card-text',{staticClass:"flex-row mt-3 d-flex",staticStyle:{"width":"100%"}},[_c('div',[_c('v-img',{attrs:{"src":require('@/assets/svg/awards.svg'),"width":"30","height":"30","contain":""}})],1),_c('div',{staticClass:"mx-3 d-flex flex-column",staticStyle:{"flex-grow":"1"}},[_c('span',{staticStyle:{"font-size":"16px","font-family":"'Poppins-SemiBold'"}},[_vm._v(" "+_vm._s(coupon.title)+" ")]),_c('span',{staticStyle:{"color":"#0a5b8a","font-size":"12px","font-family":"'Poppins-Regular'"}},[_vm._v(" Valid Till : "+_vm._s(_vm.formatDate(coupon.expiryDate))+" ")]),_c('span',[_vm._v(" Goal: "+_vm._s(coupon.goal_hours)+" hrs ")]),_c('div',{staticClass:"terms-container"},[_c('span',{staticClass:"terms-title"},[_vm._v("Terms and Conditions")]),_c('span',{staticClass:"block-span"},[_vm._v(_vm._s(coupon.description))])])]),(
        _vm.profile.type === 'Volunteer' &&
        coupon.reedemedUsers.some(user => 
          user.redeemed_by === _vm.profile._id
        ) &&
        _vm.formatDate(coupon.expiryDate) !== _vm.formatDate(new Date())
      )?_c('div',[_c('div',{staticClass:"code-title"},[_vm._v("Coupon Code Revealed!")]),_c('div',{staticClass:"code",on:{"click":_vm.copyCouponCode}},[_vm._v(" "+_vm._s(coupon.couponCode)+" ")])]):_vm._e(),(_vm.isExpired(coupon.expiryDate))?_c('div',{staticClass:"code"},[_vm._v("EXPIRED")]):_vm._e()]),_c('v-card-text',{staticClass:"pt-0 mt-0"},[_c('div',{staticClass:"position-relative"},[(_vm.text_collapsed)?_c('span',{directives:[{name:"linkified",rawName:"v-linkified"}],staticClass:"text-block",domProps:{"textContent":_vm._s(_vm.collapsed_text)}}):_vm._e()])]),_c('v-snackbar',{model:{value:(_vm.snackbar),callback:function ($$v) {_vm.snackbar=$$v},expression:"snackbar"}},[_vm._v(" "+_vm._s(_vm.snackMessage)+" ")])],1)}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }