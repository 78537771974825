<template>
  <div>
    <v-card
      v-for="coupon in reedemedCoupons"
      :key="coupon._id"
      style="width: 100%"
    >
      <v-card-text class="flex-row mt-3 d-flex" style="width: 100%">
        <div>
          <v-img
            :src="require('@/assets/svg/awards.svg')"
            width="30"
            height="30"
            contain
          />
        </div>
        <div class="mx-3 d-flex flex-column" style="flex-grow: 1">
          <span style="font-size: 16px; font-family: 'Poppins-SemiBold'">
            {{ coupon.title }}
          </span>
          <span
            style="
              color: #0a5b8a;
              font-size: 12px;
              font-family: 'Poppins-Regular';
            "
          >
           Valid Till : {{ formatDate(coupon.expiryDate) }}
          </span>
          <span> Goal: {{ coupon.goal_hours }} hrs </span>
            <div class="terms-container">
                <span class="terms-title">Terms and Conditions</span>
                <span class="block-span">{{coupon.description}}</span>
            </div>
        </div>
       <div v-if="
          profile.type === 'Volunteer' &&
          coupon.reedemedUsers.some(user => 
            user.redeemed_by === profile._id
          ) &&
          formatDate(coupon.expiryDate) !== formatDate(new Date())
        ">
          <div class="code-title">Coupon Code Revealed!</div>
          <div class="code" @click="copyCouponCode">
            {{ coupon.couponCode }}
          </div>
        </div>
          <div class="code" v-if="isExpired(coupon.expiryDate)">EXPIRED</div>
      </v-card-text>
      <v-card-text class="pt-0 mt-0">
        <div class="position-relative">
          <span
            class="text-block"
            v-if="text_collapsed"
            v-linkified
            v-text="collapsed_text"
          />
          <!-- <span
            v-else
            v-text="award.description"
            class="text-block"
            v-linkified
          /> -->
        </div>
        <!-- <img :src="award.couponCode.find(coupon => coupon.redeemed_by === profile._id).qrcode" alt="QrCode" class="qrCode"> -->
      </v-card-text>
      <v-snackbar v-model="snackbar">
        {{ snackMessage }}
      </v-snackbar>
    </v-card>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import dateFormat from "dateformat";
// import EditableAvatar from "../../components/EditableAvatar.vue";
export default {
  components: {
    // EditableAvatar,
  },
  props: {
    award: Object,
    activeConversation: Object,
    hideButtons: {
      type: Boolean,
      default: false,
    },
    onClicked: Function,
    eligibles: Array,
   
  },
  data() {
    return {
      snackbar: false,
      snackMessage: null,
      text_collapsed: false,
      collapsed_text: "",
      code: "",
      coupon: false,
      editDialog: false,
      sendCouponDialog: false,
      couponShowDialog: false,
      deleteDialog: false,
      users: [],
      couponWinners: {},
      showCouponcodes: {},
      couponRevealed: false,
      myHours: 0,
      reedemedCoupons: []
    };
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
      profileType: "type",
    }),
    ...mapGetters({
      timezone: "usersetting/getTimeZone",
    }),
    // reedemedCoupons() {
    //   return this.award.couponCode.filter(
    //     (coupon) => coupon.redeemed_by === this.profile._id
    //   );
    // },
  },
  methods: {
    ...mapActions("awards", {
      deleteAward: "deleteAward",
      addChat: "chat/addChat",
      getVolunteerHours: "getVolunteerHours",
      getEligibles: "getEligibles",
      fetchAllAwards: "fetchAllAwards",
      initConversationsClient: "chat/initConversationsClient",
      updateConversation: "chat/updateConversation",
      sendMessageNotification: "chat/sendMessageNotification",
      conversationsClient: "chat/conversationsClient",
      redeemedAwards: "fetchRedeemedAwards",
    }),
    goToDetails() {
      // if (!this.onClicked) {
      //   this.$router.push({
      //     name: "agency-need-details",
      //     query: {
      //       _id: this.award._id,
      //     },
      //   });
      // } else {
      //   this.onClicked(this.award._id);
      // }
    },
    onCloseDialog() {
      this.dialog = false;
    },
    async sendCoupon(awardId, users_id) {
      const couponWinners = {
        awardId,
        users_id,
      };
      this.couponWinners = couponWinners;
      this.sendCouponDialog = true;
    },
    async copyCouponCode() {
      try {
        await navigator.clipboard.writeText(this.code);
        this.snackMessage = "Coupon code copied to clipboard!";
        this.snackbar = true;
        setTimeout(() => {
          this.message = null;
        }, 2000);
      } catch (err) {
        console.error("Failed to copy: ", err);
        this.message = "Failed to copy coupon code!";
      }
    },

    redeemedCoupons() {
      this.redeemedAwards({})
        .then((r) => {
          this.snackMessage = "Coupon code redeemed!";
          this.snackbar = true;
          this.reedemedCoupons = r;
          setTimeout(() => {
            this.message = null;
          }, 2000);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    goToAgencyProfile(agency) {
      if (agency)
        this.$router.push({
          name: "agency-profile-details",
          params: { id: agency },
        });
    },
    couponView(award) {
      const showCouponcodes = {
        // couponCode: award.couponCode,
        awardId: award._id,
      };
      this.showCouponcodes = showCouponcodes;
      this.couponShowDialog = true;
    },
    handleCouponRevealed(couponRevealed) {
      this.couponRevealed = couponRevealed;
    },
    getCollapedText() {
      // if (!this.award.description) {
      //   this.text_collapsed = false;
      //   return;
      // }
      const full_text = this.award.description.trim();
      const textArray = full_text.split(/\r\n|\r|\n/);

      if (textArray.length > 3) {
        this.collapsed_text = textArray.slice(0, 3).join("\n");
        this.text_collapsed = true;
        return;
      }

      // Check for the number of characters
      if (full_text.length > 270) {
        this.collapsed_text = full_text.substring(0, 270);
        this.text_collapsed = true;
        return;
      }

      // If neither condition is met, set collapsed_text to full_text
      this.collapsed_text = full_text;
      this.text_collapsed = false;
    },
    formatDate(date) {
      return dateFormat(
        this.convertTZ(date, this.timezone),
        "mm/dd/yyyy"
      );
    },
    convertTZ(date, tzString) {
      if (!date) return "";
      return new Date(
        (typeof date === "string" ? new Date(date) : date).toLocaleString(
          "en-US",
          {
            timeZone: tzString ? tzString : "America/New_York",
          }
        )
      );
    },
    formatMinutes(value) {
      var hrs = Math.floor(value / 60);
      var str = `${hrs}:`;
      // if (hrs > 9) str += `${hrs}:`;
      // else str += `0${hrs}:`;
      var mins = Math.floor(value % 60);
      if (mins > 9) str += `${mins}`;
      else str += `0${mins}`;
      return str;
    },
  isExpired(expiryDate) {
    if (!expiryDate) return false; 

    const today = this.formatDate(new Date());
    const expiry = this.formatDate(expiryDate);

    return expiry <= today; 
  },
  },
  mounted() {
    this.redeemedCoupons();
    this.handleCouponRevealed();
    //this.getCollapedText();
  },
};
</script>

<style scoped>
.code-title {
  color: green;
  font-size: 13px;
  font-family: "Poppins-Regular";
  margin: 0px;
  position: absolute;
  left: 555px;
}
.code {
  color: red;
  font-family: "Poppins-Regular";
  font-size: 15px;
  margin: 20px 129px 0px 0px;
}
.qrCode {
  position: absolute;
  right: 10px;
  top: 26px;
  width: 100px;
  height: 100px;
}
.terms-container{
    padding: 13px;
    margin-top: 11px;
    border-radius: 10px;
    border: 2px solid #FFCF50;
    background-color: #f4e7c0;
}
.terms-title{
      color: #F0A04B;
    font-weight: 800;
}
.block-span {
  display: block;
  color: #797875;
}
</style>
