<template>
    <div class="awards-tab-section">
        <v-tabs v-model="activeTab" grow>
            <v-tab class="tab" key="section1">Active</v-tab>
            <v-tab class="tab" key="section2">Reedeemed</v-tab>

            <v-tab-item key="section1">
                <AwardsActiveListSection />
            </v-tab-item>
            <v-tab-item key="section2" v-if="profileType == 'Volunteer'">
                <AwardsReedemedListSectionVue />
            </v-tab-item>
        </v-tabs>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import AwardsActiveListSection from "./AwardsActiveListSection.vue";
import AwardsReedemedListSectionVue from './AwardsReedemedListSection.vue';
export default {
    components: {
        AwardsActiveListSection,
        AwardsReedemedListSectionVue
    },
    data() {
        return {
            activeTab: 'section1'
        };
    },
  computed: {
    ...mapGetters({
      profileType: "auth/getType"
    })
  }
  };
</script>

<style scoped>
.tab {
    color: #3B7CA1;
    min-width: 450px;
}

.awards-tab-section {
    margin-top: 20px;
}

@media (max-width: 600px) {
    .tab {
        min-width: 200px;
    }
}
</style>
